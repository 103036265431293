<template>
  <div
    class="article-item"
    @click="
      $router.push({ name: 'composite-details', params: { id: item.id } })
    "
  >
    <div class="article-intro">
      <p class="article-title">
        {{ item.title }}
      </p>
      <div class="article-content">
        <p>
              {{ item.releaseTime | relativeTime  }}
        </p>
      </div>
    </div>
    <div class="img">
      <img :src="item.frontCover[0]" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    item: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  components: {},
  data() {
    return {}
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {}
}
</script>

<style lang="less" scoped>
.article-item {
  margin: 25px;

  .article-intro {
    box-sizing: border-box;

    margin-right: 14px;
    .article-title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 343px;
      height: 40px;
      font-size: 24px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      text-align: LEFT;
      color: #12151b;
    }
    .article-content {
      p {
        width: 343px;
        font-size: 18px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: LEFT;
        color: #464a55;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
    }
  }
  .img {
    width: 330px;
    height: 193px;
    img {
      border-radius: 4px;
      width: 330px;
      height: 193px;
    }
  }
}
</style>