import request from '@/utils/request'

// export function getComprehensiveList(params) {
//     return request({
//         url: '/lowcode/online/cgform/api/getData/0a43312a7e814a25a48c61521fe16b8c',
//         method: 'get',
//         params
//     })
// }
export function getComprehensiveList(params) {
    return request({
        url: '/lowcode/contentManagement/page',
        method: 'get',
        params
    })
}
// export function getComprehensiveItem(url) {
//     return request({
//         url: `/lowcode/online/cgform/api/form/0a43312a7e814a25a48c61521fe16b8c/${url}`,
//         method: 'get',
//     })

// }
export function getComprehensiveItem(params) {
        return request({
            url: '/lowcode/contentManagement/detail',
            method: 'get',
            params
        })
    
    }
    export function getMessageList(data) {
        return request({
            url: '/lowcode/contentManagement/articleMessage/page',
            method: 'POST',
            data
        })
    
    }
export function getPublish(data) {
    return request({
        url: '/lowcode/contentManagement/publish',
        method: 'POST',
        data
    })
}
export function getReplyComments(data) {
    return request({
        url: '/lowcode/contentManagement/articleMessage/replyMessagePage',
        method: 'POST',
        data
    })
}
export function deleteMessage(params) {
    return request({
        url: '/lowcode/contentManagement/articleMessage/delete',
        method: 'get',
        params
    })
}
export function getAllArticleType(params) {
    return request({
        url: `/lowcode/articleType/getAllArticleType`,
        method: 'get',
        params
    })
}

export function ArticlePageview(params) {
    return request({
        url: `/lowcode/contentManagement/articleView`,
        method: 'get',
        params
    })
}

