<template>
  <div class="comprehensive-container">
    <Layout class="Layout" :hd="true" :ft="false">
      <!-- 左边按键 -->
      <div slot="le" class="hd-left" @click="$router.push('/')">
        <div></div>
      </div>
      <div slot="title">
        <span>{{ Title }}</span>
      </div>
      <van-tabs
        title-active-color="#10955B"
        color="#10955B"
        :class="[
          !$store.state.user.elderModel ? 'Article-tabs' : 'Article-tabs-elder'
        ]"
        v-model="activeName"
        swipeable
      >
        <van-tab
          :name="ArticleItem.id"
          :title="ArticleItem.name"
          v-for="ArticleItem in ArticleList"
          :key="ArticleItem.id"
          :to="{ name: 'comprehensive', params: { id: ArticleItem.id } }"
          replace
        >
          <!-- 文章列表 -->
          <ArticleList v-if="!$store.state.user.elderModel"></ArticleList>
          <ArticleListElder v-else></ArticleListElder>

          <!-- 文章列表 -->
        </van-tab>
      </van-tabs>
    </Layout>
  </div>
</template>

<script>
import Layout from '@/components/layout.vue'
import ArticleList from './article-list.vue'
import ArticleListElder from './articleList-elder.vue'
import { getAllArticleType } from '@/api/comprehensive'
export default {
  name: '',
  components: {
    ArticleList,
    Layout,
    ArticleListElder
  },
  data() {
    return {
      activeName: null,
      Title: '',
      ArticleList: [
       
      ]
    }
  },
  computed: {},
  created() {
    this.activeName = this.$route.params.id
  },
  watch: {
    $route: {
      handler() {
        this.getAllArticleType()
      },
      immediate: true
    }
  },
  mounted() {},
  methods: {
    getTitle() {
      this.Title = this.ArticleList.find((item) => {
        if (item.id == this.$route.params.id) {
          return true
        }
      }).name
    },
    getAllArticleType() {
      getAllArticleType().then((res) => {
        this.ArticleList = res.result
        this.getTitle()
      })
    }
    // getTitle(type) {
    //   switch (type) {
    //     case 1:
    //       return '创业帮扶'
    //     case 2:
    //       return '一站注册'
    //     case 3:
    //       return '财税服务'
    //     case 4:
    //       return '融资服务'
    //     case 5:
    //       return '法律服务'
    //     case 6:
    //       return '数字营销'
    //     case 7:
    //       return '科技申报'
    //     case 8:
    //       return '设计服务'
    //     case 9:
    //       return '技术服务'
    //     case 0:
    //       return '人力资源'
    //   }
    // }
  }
}
</script>

<style lang="less" scoped>
.Layout /deep/ .main {
  padding-bottom: 0;
}
.Article-tabs {
  border-bottom: 1px solid rgb(226, 226, 226);
  &/deep/.van-tab__text {
    font-weight: 600;
  }
}
.Article-tabs-elder {
  border-bottom: 1px solid rgb(226, 226, 226);
  &/deep/.van-tab__text {
    font-size: 18px;
    font-weight: 700;
  }
}
.comprehensive-container {
  background-color: #fff;
  .hd-left {
    display: flex;
    align-items: center;
    div {
      width: 10px;
      height: 10px;
      border-top: 2px solid #ffffff;
      border-right: 2px solid #ffffff;
      transform: rotate(225deg);
    }
    span {
      font-size: 18px;
      margin-left: 4px;
    }
  }
}
</style>
