<template>
  <div class="article-list">
    <van-pull-refresh
      v-model="isLoading"
      @refresh="onRefresh"
      :success-text="refreshSuccessText"
      :success-duration="1500"
    >
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="暂无更多"
        @load="onLoad"
      >
        <div class="article-list-content" v-for="item in list" :key="item.id">
          <ArticleItem :item="item" />
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import ArticleItem from './article-item.vue'
import { getComprehensiveList } from '@/api/comprehensive'
export default {
  name: 'ArticleList',
  components: {
    ArticleItem
  },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      isLoading: false,
      refreshSuccessText: '刷新成功',
      pageNo: 1,
      pageSize: 10
    }
  },
  computed: {},
  watch: {},
  created() {},

  mounted() {},
  methods: {
    async onLoad() {
      try {
        const result = await getComprehensiveList({
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          articleTypeId: this.$route.params.id
          // column: 'createTime',
          // order: 'desc'
        })
        const list = result.result.records
        list.forEach((item) => {
          item.frontCover = item.frontCover.split(',')
        })
        const filterList = list.filter(function (item) {
          return item.releaseStatus == 1
        })
        this.list.push(...filterList)
        this.loading = false
        if (result.result.records.length) {
          this.pageNo++
        } else {
          this.finished = true
        }
      } catch (error) {
        this.loading = false
      }
    },
    onRefresh() {
    this.list = []
    this.pageNo = 1
    this.onLoad()
    }
  }
}
</script>

<style scoped lang="less">
.article-list {
  position: fixed;
  top: 88px;
  bottom: 0;
  overflow-y: auto;
  right: 0;
  left: 0;
  background-color: #fff;
}
</style>